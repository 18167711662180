import { getLocalStorage, getSupportLanguage, isInWebview, stateGetter } from '@/libs/utils'
import { getOSName, getUrlQuery } from '@/libs/tools'

export default {
  state: {
    language: '', // 语言：zh-CN, en-US
    isZh: '', // 当前域名是否是.cn(主要用于显示不同的界面，和state.langtuage无关)
    schemeName: '', // 和客户端交互的跳转协议
    deviceType: '', // 设备类型，必须在四个类型中，否则使用getOsName()获取，MAC，WIN，IOS，ANDROID，默认：获取当前系统类型
    productName: '', // 产品名称，默认：UPDF
    source: '', // 注册来源(更细的来源，比如来自官网某个页面),默人：WEB
    subPid: '', // 渠道id
    loginFrom: '', // 登录来源：checkout，website，online
    webviewVisibilityState: true, // webview隐藏显示状态,默认显示
    debugInfo: '', // 调试信息：Object或String
    isAppStore: '', // 是否是从 appStore 来的
    disabledZgaApi: false, // 是否一直禁用zga访问，如果接口遇到超时请求，则禁用zga访问
    defaultAccount: '', // 默认登录账号
    disabledAccountInput: false, // 登录时是否可修改账号(前提是有默认账号)
  },
  getters: {
    // 是否使用zga域名接口
    useZgaApi: (state, getters) => import.meta.env.MODE === 'prod' && !state.disabledZgaApi && getters.isZh,
    zgaApiHost: () => (import.meta.env.MODE === 'local' ? 'zga-test-account.updf.cn' : import.meta.env.VITE_API_PREFIX_CN_ZGA),
    mode: () => import.meta.env.MODE, // 当前运行环境
    language: state => {
      const lang = stateGetter(state, 'language', 'en-US')
      return getSupportLanguage(lang)
    },
    schemeName: state => stateGetter(state, 'schemeName', '', 2),
    deviceType: state => {
      const mustIn = ['MAC', 'WIN', 'IOS', 'ANDROID']
      if (mustIn.includes(state.deviceType)) return state.deviceType
      return getOSName()
    },
    productName: state => stateGetter(state, 'productName', 'Unknown'),
    source: state => stateGetter(state, 'source', 'Unknown'),
    subPid: state => stateGetter(state, 'subPid', ''),
    isAppStore: state => stateGetter(state, 'isAppStore', false),
    webviewVisibilityState: state => state.webviewVisibilityState,
    isZh: state => {
      // 当前域名是否是.cn(即国内域名)
      if (state.isZh === '') {
        // 线上环境根据访问域名判断是中文界面还是英文界面
        // 本地或线上测试地址可以修改localStorage isZh 或者 url中设置isCN=1修改中英文界面
        const testIsZh = getLocalStorage('isZh') === 'true' || getUrlQuery('isCN') === '1'
        const isZh = import.meta.env.MODE === 'prod' ? location.host.indexOf('updf.cn') >= 0 : testIsZh
        state.isZh = isZh
      }
      return state.isZh
    },
    isInWebview: () => isInWebview(), // 是否在内嵌页
    isMac: state => {
      // 是否是mac
      return stateGetter(state, 'deviceType', 'WEB') === 'MAC'
    },
    isWin: state => {
      // 是否是windows
      return stateGetter(state, 'deviceType', 'WEB') === 'WIN'
    },
    isiOS: state => {
      // 是否是iOS
      return stateGetter(state, 'deviceType', 'WEB') === 'IOS'
    },
    isAndroid: state => {
      // 是否是Android
      return stateGetter(state, 'deviceType', 'WEB') === 'ANDROID'
    },
    isIframe: (state, getters) => {
      // 是否是web引入，比如官网，收银台(注：web引入目前都是通过iframe方式，也是通过postMessage方式和父页面通讯)
      return window.top !== window && !getters.isMac && !getters.isWin && !getters.isiOS && !getters.isAndroid
    },
    isWeb: (state, getters) => {
      // 网页直接打开登录页的方式
      return !getters.isIframe && !getters.isMac && !getters.isWin && !getters.isiOS && !getters.isAndroid
    },
    isLoginFromCheckout: state => {
      // 是否是在收银台登录
      return stateGetter(state, 'loginFrom', 'website') === 'checkout'
    },
  },
  mutations: {},
  actions: {},
}
